import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import spriteSVG from "../sprite/sprite-build.svg"

const TeamPage = ({ data }) => {
  const team = data.team_member.nodes

  return (
    <Layout>
      <Helmet>
        <body className="home"></body>
      </Helmet>
      <SEO title="Team" />

      <section className="c-section c-section--pagehead">
        <div className="o-container o-v-middle">
          <div className="o-flex-wrap">
            <h1 className="o-type-6">Team</h1>
            <a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">
              Join the Wait List
            </a>
          </div>
        </div>
      </section>

      <section className="c-section c-section--gradient u-pb-x8@md">
        <div className="o-container">
          <div className="o-title-text">
            <h2 className="o-type-5 u-mr-x8 u-mb-x2">Stay&nbsp;connected</h2>
            <div className="o-type-2">
              <p>Join our community of developers and privacy experts for support, inspiration and to help advance privacy standards around the world</p>
            </div>
          </div>
          <div className="u-flex u-jc-end u-mt-x2 u-mt-x5@md">
            <a href="https://discord.gg/qBRVf4Sxxw" className="c-button u-flex u-ai-start">
              Discord{" "}
              <svg className="u-fill-white u-width-x3 u-ml" viewBox="0 0 32 32">
                <use xlinkHref={spriteSVG + "#IconDiscord"}></use>
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="c-section c-section--team">
        <div className="o-container">
          <div className="o-type-4 u-mb-x4 u-mv-x8@md u-mb-x20@md">
            <p>
              <em>
                <strong>PAD</strong> was founded by a team of privacy researchers and advocates, who created PAD to help preserve privacy in our online lives. If you want to help
                us on this journey, join our Discord server and say hello.
              </em>
            </p>
          </div>

          <h2 className="c-section__subtitle u-mb-x10@md">Our Team</h2>

          <div className="">
            <div className="c-team o-grid o-grid--team">
              {team.map(item => (
                <div key={item.id} className="c-team__card o-grid__col u-1/2@sm u-1/3@md">
                  <header className="u-mb-x2">
                    <h3 className="o-type-3 u-weight-lighter">{item.frontmatter.title}</h3>
                    {/* <p className="o-type-1">{item.frontmatter.position}</p> */}
                  </header>
                  <figure className="u-mb-x2 u-mb-x3@md">
                    <Img fluid={item.frontmatter.thumbnail.childImageSharp.fluid} alt="" />
                  </figure>
                  <div className="o-type-2" dangerouslySetInnerHTML={{ __html: item.html }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query statutarniOrgany {
    team_member: allMarkdownRemark(filter: { fields: { collection: { eq: "team_member" } } }, sort: { fields: [frontmatter___order], order: ASC }) {
      totalCount
      nodes {
        id
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 400) {
                presentationHeight
                sizes
                src
                srcSet
                presentationWidth
                aspectRatio
              }
            }
          }
        }
        html
      }
    }
  }
`

export default TeamPage
